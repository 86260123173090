import { GET_COUNTRY_LIST_REQUEST, GET_COUNTRY_LIST_SUCCESS, GET_COUNTRY_LIST_FAILURE } from './actions';

const initialState = {
  data: null,
  error: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
  case GET_COUNTRY_LIST_REQUEST:
    return { ...state, error: '' };
  case GET_COUNTRY_LIST_SUCCESS:
    return { ...state, data: payload };
  case GET_COUNTRY_LIST_FAILURE:
    return { ...state, error: payload };
  default:
    return state;
  }
}
