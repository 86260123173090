import { Get, Put, Post, Delete } from "redux/services";
import { call, put, takeLatest } from "redux-saga/effects";
import { alertMessage } from "components/CleanUIComponents/AlertMessage";
import { notification } from 'antd';

import {
    CREATE_SCENARIO_FAILURE, CREATE_SCENARIO_REQUEST, CREATE_SCENARIO_SUCCESS,
    getAllScenariosRequest,
    GET_ALL_SCENARIOS_FAILURE, GET_ALL_SCENARIOS_REQUEST, GET_ALL_SCENARIOS_SUCCESS,
    OPTIMIZE_REQUEST, OPTIMIZE_SUCCESS, OPTIMIZE_FAILURE,
    GET_OPTIMIZE_RESULT_FAILURE, GET_OPTIMIZE_RESULT_REQUEST, GET_OPTIMIZE_RESULT_SUCCESS, DELETE_SCENARIO_SUCCESS, DELETE_SCENARIO_FAILURE, DELETE_SCENARIO_REQUEST
} from "./actions";

const openNotification = placement => {
    notification.info({
        message: `Yeni optimizasyon başlatıldı`,
        description:
            'Tahmini işlem süresi 10 dakika.',
        placement,
        duration: 0
    });
};

function* createScenarioSaga({ payload }) {
    try {
        const response = yield call(Post, `/Scenario/createScenario`, payload, {}, false);
        if (response && !response.errors) {
            yield put({ type: CREATE_SCENARIO_SUCCESS, payload: response });
            yield put(getAllScenariosRequest({}))
            alertMessage("Senaryo başarıyla kaydedildi", "success");
        } else {
            yield put({ type: CREATE_SCENARIO_FAILURE, payload: response });
            alertMessage(response.message, "error");
        }
    } catch (error) {
        alertMessage("can not created successfuly", "error");
        yield put({ type: CREATE_SCENARIO_FAILURE, payload: error });
    }
}

function* getAllScenariosSaga({ payload }) {
    try {
        const response = yield call(Get, `/Scenario/getAllScenario`, payload, {}, false);
        if (response && !response.errors) {
            yield put({ type: GET_ALL_SCENARIOS_SUCCESS, payload: response });
        } else {
            yield put({ type: GET_ALL_SCENARIOS_FAILURE, payload: response });
            alertMessage(response.message, "error");
        }
    } catch (error) {
        alertMessage(error, "error");
        yield put({ type: GET_ALL_SCENARIOS_FAILURE, payload: error });
    }
}

function* optimizeScenarioSaga({ payload }) {
    try {
        const response = yield call(Post, `/Scenario/optimizeScenario`, payload, {}, false);
        if (response && !response.errors) {
            yield put({ type: OPTIMIZE_SUCCESS, payload: response });
            openNotification('topRight')
        } else {
            yield put({ type: OPTIMIZE_FAILURE, payload: response });
            alertMessage('Error', "error");
        }
    } catch (error) {
        alertMessage(error, "error");
        yield put({ type: OPTIMIZE_FAILURE, payload: error });
    }
}

function* getOptimizeResultSaga({ payload }) {
    try {
        const response = yield call(Post, `/Scenario/getOptimizationResultByScenarioId?scenarioId=${payload.scenarioId}`, {}, {}, false);
        if (response && !response.errors) {
            yield put({ type: GET_OPTIMIZE_RESULT_SUCCESS, payload: response });
        } else {
            yield put({ type: GET_OPTIMIZE_RESULT_FAILURE, payload: response });
            alertMessage('Error', "error");
        }
    } catch (error) {
        alertMessage(error, "error");
        yield put({ type: GET_OPTIMIZE_RESULT_FAILURE, payload: error });
    }
}


function* deletScenarioSaga({ payload }) {
    try {
        const response = yield call(Delete, `/Scenario/deleteScenario?scenarioId=${payload.scenarioId}`, {}, {}, false);
        if (response && !response.error) {
            yield put({ type: DELETE_SCENARIO_SUCCESS, payload: response });
            alertMessage("Senaryo başarıyla silindi", "success");
            yield put(getAllScenariosRequest({}));
        } else {
            yield put({ type: DELETE_SCENARIO_FAILURE, payload: response });
            alertMessage(response.message, "error");
        }
    } catch (error) {
        alertMessage("Basarisiz", "error");
        yield put({ type: DELETE_SCENARIO_FAILURE, payload: error });
    }
}

export default function* Saga() {
    yield takeLatest(CREATE_SCENARIO_REQUEST, createScenarioSaga);
    yield takeLatest(GET_ALL_SCENARIOS_REQUEST, getAllScenariosSaga);
    yield takeLatest(OPTIMIZE_REQUEST, optimizeScenarioSaga);
    yield takeLatest(GET_OPTIMIZE_RESULT_REQUEST, getOptimizeResultSaga);
    yield takeLatest(DELETE_SCENARIO_REQUEST, deletScenarioSaga);

}

