import React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import { compose } from 'lodash/fp'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'
import { readLocalStorage } from 'helpers'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })


const loginPages = [
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },
  {
    path: '/user/reset',
    component: loadable(() => import('pages/user/reset')),
    exact: true,
  },
  {
    path: '/pages/login-alpha',
    component: loadable(() => import('pages/pages/login-alpha')),
    exact: true,
  },
  {
    path: '/pages/login-beta',
    component: loadable(() => import('pages/pages/login-beta')),
    exact: true,
  },
  {
    path: '/pages/register',
    component: loadable(() => import('pages/pages/register')),
    exact: true,
  },
]

const routes = [

  {
    path: '/dashboard',
    component: loadable(() => import('pages/dashboard/alpha')),
  },
  {
    path: '/shipment/track/:uuid',
    component: loadable(() => import('pages/trackAndStatus')),
    exact: true,
  },
  {
    path: '/sirketler',
    component: loadable(() => import('pages/users')),
    exact: true,
    isAdmin: true,
  },
  {
    path: '/sirketler/:id',
    component: loadable(() => import('pages/users/components/editUser')),
    exact: true,
    isAdmin: true,
  },
  {
    path: '/sirket-ekle',
    component: loadable(() => import('pages/users/components/addUser')),
    exact: true, isAdmin: true,
  },
  {
    path: '/santraller',
    component: loadable(() => import('pages/Centrals')),
    exact: true,
    isAdmin: true,
  },
  {
    path: '/periyotlar',
    component: loadable(() => import('pages/periods')),
    exact: true,
  },
  {
    path: '/logisticCompanies',
    component: loadable(() => import('pages/logisticCompanies')),
    exact: true,
  },
  {
    path: '/logisticCompanies/addCompany',
    component: loadable(() => import('pages/logisticCompanies/components/addCompany')),
    exact: true,
  },
  {
    path: '/editLogisticCompanies/:uuid',
    component: loadable(() => import('pages/logisticCompanies/components/editCompany')),
    exact: true,
  },
  {
    path: '/senaryolar',
    component: loadable(() => import('pages/scenarios')),
    exact: true,
  },

  // Apps
  {
    path: '/apps/profile',
    component: loadable(() => import('pages/apps/profile')),
    exact: true,
  },
]

const customerRoutes = [
  {
    path: '/periyotlar',
    component: loadable(() => import('pages/periods')),
    exact: true,
  },
  {
    path: '/senaryolar',
    component: loadable(() => import('pages/scenarios')),
    exact: true,
  },
]

class Router extends React.Component {
  render() {
    const { history, profile } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/sirketler" />} />
            {readLocalStorage('user')?.isAdmin ? routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            )) :
              customerRoutes.map(route => (
                <Route
                  path={route.path}
                  component={route.component}
                  key={route.path}
                  exact={route.exact}
                />
              ))
            }

            {loginPages.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
})


export default compose(connect(mapStateToProps, null))(Router)

