export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE';

export const GET_SELECTED_USER_REQUEST = 'GET_SELECTED_USER_REQUEST';
export const GET_SELECTED_USER_SUCCESS = 'GET_SELECTED_USER_SUCCESS';
export const GET_SELECTED_USER_FAILURE = 'GET_SELECTED_USER_FAILURE';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const getUserListRequest = payload => ({
  type: GET_USER_LIST_REQUEST,
  payload,
});

export const getSelectedUserRequest = payload => ({
  type: GET_SELECTED_USER_REQUEST,
  payload,
});

export const addUserRequest = payload => ({
  type: ADD_USER_REQUEST,
  payload,
});

export const editUserRequest = payload => ({
  type: EDIT_USER_REQUEST,
  payload,
});

export const deleteUserRequest = payload => ({
  type: DELETE_USER_REQUEST,
  payload,
});



