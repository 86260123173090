import { Post, Get } from "redux/services";
import { call, put, takeLatest } from "redux-saga/effects";
import { readLocalStorage } from 'helpers';
import { SEARCH_ADDRESS_FAILURE,SEARCH_ADDRESS_REQUEST,SEARCH_ADDRESS_SUCCESS } from "./actions";


function* searchAddressSaga({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const response = yield call(Get,  `/places/search?address=${payload.value}`,{}, loginData.token, true);
    if (response && !response.error) {
      yield put({ type: SEARCH_ADDRESS_SUCCESS, payload: response.data });
    } else {
      yield put({ type: SEARCH_ADDRESS_FAILURE, payload: response });
    }
  } catch (error) {
    yield put({ type: SEARCH_ADDRESS_FAILURE, payload: error });
  }
}

 export default function* Saga() {
    yield takeLatest(SEARCH_ADDRESS_REQUEST, searchAddressSaga);
  }