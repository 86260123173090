import { Get, Put, Post, Delete } from "redux/services";
import { call, put, takeLatest } from "redux-saga/effects";
import { readLocalStorage } from 'helpers';
import { push } from 'react-router-redux';
import { alertMessage } from "components/CleanUIComponents/AlertMessage";
import {
    CREATE_PERIOD_FAILURE, CREATE_PERIOD_REQUEST, CREATE_PERIOD_SUCCESS, DELETE_PERIOD_FAILURE, DELETE_PERIOD_REQUEST, DELETE_PERIOD_SUCCESS, EDIT_PERIOD_FAILURE, EDIT_PERIOD_REQUEST, EDIT_PERIOD_SUCCESS, getPeriodRequest, GET_PERIOD_BY_USER_FAILURE, GET_PERIOD_BY_USER_REQUEST, GET_PERIOD_BY_USER_SUCCESS, GET_PERIOD_FAILURE, GET_PERIOD_REQUEST, GET_PERIOD_SUCCESS
} from "./actions";


function* createPeriodSaga({ payload }) {
    try {
        const response = yield call(Post, `/Period/createPeriod`, payload, {}, false);
        if (response && !response.error) {
            yield put({ type: CREATE_PERIOD_SUCCESS, payload: response });
            alertMessage("created successfuly", "success");
            yield put(getPeriodRequest({ centralId: payload.centralId }))
        } else {
            yield put({ type: CREATE_PERIOD_FAILURE, payload: response });
            alertMessage(response.message, "error");
        }
    } catch (error) {
        alertMessage("can not created successfuly", "error");
        yield put({ type: CREATE_PERIOD_FAILURE, payload: error });
    }
}

function* getPeriodSaga({ payload }) {
    try {
        const response = yield call(Get, `/Period/getPeriod?centralId=${payload.centralId}`, {}, {}, false);
        if (response && !response.error) {
            yield put({ type: GET_PERIOD_SUCCESS, payload: response });
        } else {
            yield put({ type: GET_PERIOD_FAILURE, payload: response });
            alertMessage(response.message, "error");
        }
    } catch (error) {
        yield put({ type: GET_PERIOD_FAILURE, payload: error });
        alertMessage("Failed to fetch", "error");
    }
}

function* getPeriodByUserSaga({ payload }) {
    const user = readLocalStorage('user')
    try {
        const response = yield call(Get, `/Period/getPeriodByUserId?userId=${user.userId}`, {}, {}, false);
        if (response && !response.error) {
            yield put({ type: GET_PERIOD_BY_USER_SUCCESS, payload: response });
        } else {
            yield put({ type: GET_PERIOD_BY_USER_FAILURE, payload: response });
            alertMessage(response.message, "error");
        }
    } catch (error) {
        yield put({ type: GET_PERIOD_BY_USER_FAILURE, payload: error });
        alertMessage("Failed to fetch", "error");
    }
}

function* deletPeriodSaga({ payload }) {
    try {
        const response = yield call(Delete, `/Period/deletePeriod?periodId=${payload.periodId}`, {}, {}, false);
        if (response && !response.error) {
            yield put({ type: DELETE_PERIOD_SUCCESS, payload: response });
            alertMessage("Period başarıyla silindi", "success");
            yield put(getPeriodRequest({}));
        } else {
            yield put({ type: DELETE_PERIOD_FAILURE, payload: response });
            alertMessage(response.message, "error");
        }
    } catch (error) {
        alertMessage("Basarisiz", "error");
        yield put({ type: DELETE_PERIOD_FAILURE, payload: error });
    }
}

function* editPeriodSaga({ payload, afterSuccess }) {
    try {
        const response = yield call(Post, `/Period/updatePeriod`, payload, {}, false);
        if (response && !response.error) {
            yield put({ type: EDIT_PERIOD_SUCCESS, payload: response });
            alertMessage("Period başarıyla guncellendi", "success");
            afterSuccess()
            yield put(getPeriodRequest({ centralId: payload.centralId }));
        } else {
            yield put({ type: EDIT_PERIOD_FAILURE, payload: response });
            alertMessage(response.message, "error");
        }
    } catch (error) {
        alertMessage("Basarisiz", "error");
        yield put({ type: EDIT_PERIOD_FAILURE, payload: error });
    }
}

export default function* Saga() {
    yield takeLatest(CREATE_PERIOD_REQUEST, createPeriodSaga);
    yield takeLatest(GET_PERIOD_REQUEST, getPeriodSaga);
    yield takeLatest(DELETE_PERIOD_REQUEST, deletPeriodSaga);
    yield takeLatest(EDIT_PERIOD_REQUEST, editPeriodSaga);
    yield takeLatest(GET_PERIOD_BY_USER_REQUEST, getPeriodByUserSaga);
}

