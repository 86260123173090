import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import menu from './menu/reducers'
import settings from './settings/reducers'
import auth from "./auth/reducers"
import profile from "./profile/reducers";
import users from "./users/reducers";
import shipments from "./shipments/reducers";
import countries from "./countries/reducers";
import companies from "./company/reducers";
import address from "./address/reducers";
import directions from "./direction/reducers";
import weightTypes from "./weightTypes/reducers";
import transactions from "./trackAndStatus/reducers";
import customers from "./customers/reducers";
import selectedUser from "./users/selectedUserReducer";
import selectedCustomer from "./customers/selectedCustomerReducer";
import selectedCompany from "./company/selectedCompanyReducer"
import roles from "./roles/reducers";
import loader from "./loader/reducers";
import centrals from "./centrals/reducers"
import selectedCentral from "./centrals/selectedCentral"
import periods from "./periods/reducers"
import scenarios from "./scenarios/reducers";
import optimizeResult from "./scenarios/optimizeResult"

export default history =>
  combineReducers({
    router: connectRouter(history),
    menu,
    settings,
    auth,
    profile,
    users,
    shipments,
    countries,
    companies,
    selectedCompany,
    address,
    directions,
    weightTypes,
    transactions,
    customers,
    selectedUser,
    selectedCustomer,
    roles,
    loader,
    centrals,
    periods,
    scenarios,
    optimizeResult,
    selectedCentral
  })
