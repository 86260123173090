import { readLocalStorage } from 'helpers';
import { GET_PROFILE_INFO_REQUEST, GET_PROFILE_INFO_SUCCESS, GET_PROFILE_INFO_FAILURE } from './actions';

const initialState = {
  data: null,
  error: null,
  user: readLocalStorage('user')
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_PROFILE_INFO_REQUEST:
      return { ...state };
    case GET_PROFILE_INFO_SUCCESS:
      return { ...state };
    case GET_PROFILE_INFO_FAILURE:
      return { ...state };
    default:
      return state;
  }
}
