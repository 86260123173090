import React from 'react'
/* import { Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import HomeMenu from './HomeMenu' */
/* import ProjectManagement from './ProjectManagement'
import IssuesHistory from './IssuesHistory' */
import LiveSearch from './LiveSearch'
/* import BitcoinPrice from './BitcoinPrice'
 */ import ProfileMenu from './ProfileMenu'
import LanguageSelector from './LanguageSelector'
import styles from './style.module.scss'

class TopBar extends React.Component {
  render() {
    return (
      <div className={styles.topbar}>
        <div className="mr-auto">
          {/*  <LiveSearch /> */}
        </div>
        <div className="mr-4">
          <LanguageSelector />
        </div>
        <ProfileMenu />
      </div>
    )
  }
}

export default TopBar
