export const CREATE_SCENARIO_REQUEST = 'CREATE_SCENARIO_REQUEST';
export const CREATE_SCENARIO_SUCCESS = 'CREATE_SCENARIO_SUCCESS';
export const CREATE_SCENARIO_FAILURE = 'CREATE_SCENARIO_FAILURE';

export const GET_ALL_SCENARIOS_REQUEST = 'GET_ALL_SCENARIOS_REQUEST';
export const GET_ALL_SCENARIOS_SUCCESS = 'GET_ALL_SCENARIOS_SUCCESS';
export const GET_ALL_SCENARIOS_FAILURE = 'GET_ALL_SCENARIOS_FAILURE';

export const OPTIMIZE_REQUEST = 'OPTIMIZE_REQUEST';
export const OPTIMIZE_SUCCESS = 'OPTIMIZE_SUCCESS';
export const OPTIMIZE_FAILURE = 'OPTIMIZE_FAILURE';

export const GET_OPTIMIZE_RESULT_REQUEST = 'GET_OPTIMIZE_RESULT_REQUEST';
export const GET_OPTIMIZE_RESULT_SUCCESS = 'GET_OPTIMIZE_RESULT_SUCCESS';
export const GET_OPTIMIZE_RESULT_FAILURE = 'GET_OPTIMIZE_RESULT_FAILURE';

export const DELETE_SCENARIO_REQUEST = 'DELETE_SCENARIO_REQUEST';
export const DELETE_SCENARIO_SUCCESS = 'DELETE_SCENARIO_SUCCESS';
export const DELETE_SCENARIO_FAILURE = 'DELETE_SCENARIO_FAILURE';


export const createScenarioRequest = payload => ({
    type: CREATE_SCENARIO_REQUEST,
    payload,
});

export const getAllScenariosRequest = payload => ({
    type: GET_ALL_SCENARIOS_REQUEST,
    payload,
});

export const optimizeRequest = payload => ({
    type: OPTIMIZE_REQUEST,
    payload,
});

export const getOptimizeResultRequest = payload => ({
    type: GET_OPTIMIZE_RESULT_REQUEST,
    payload,
});

export const deleteScenarioRequest = payload => ({
    type: DELETE_SCENARIO_REQUEST,
    payload,
});


