import { TWO_DIRECTION_INFO_FAILURE, TWO_DIRECTION_INFO_SUCCESS, TWO_DIRECTION_INFO_REQUEST } from './actions';

const initialState = {
  data: null,
  error: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
  case TWO_DIRECTION_INFO_REQUEST:
    return { ...state, error: '' };
  case TWO_DIRECTION_INFO_SUCCESS:
    return { ...state, data: payload };
  case TWO_DIRECTION_INFO_FAILURE:
    return { ...state, error: payload };
  default:
    return state;
  }
}
