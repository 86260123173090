import { Delete, Get, Post, Put } from "redux/services";
import { call, put, takeLatest } from "redux-saga/effects";
import { push } from 'react-router-redux';
import { readLocalStorage } from 'helpers';
import { alertMessage } from "components/CleanUIComponents/AlertMessage";
import {
  getUserListRequest, EDIT_USER_REQUEST, EDIT_USER_FAILURE, EDIT_USER_SUCCESS, GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS, GET_USER_LIST_FAILURE, ADD_USER_FAILURE, ADD_USER_REQUEST, ADD_USER_SUCCESS,
  GET_SELECTED_USER_REQUEST, GET_SELECTED_USER_SUCCESS, GET_SELECTED_USER_FAILURE, DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE
} from "./actions";


function* getUserListSaga({ payload }) {
  try {
    const response = yield call(Get, "/User/getAllUser", payload, false);
    if (response && !response.error) {
      yield put({ type: GET_USER_LIST_SUCCESS, payload: response });
    } else {
      yield put({ type: GET_USER_LIST_FAILURE, payload: response });
    }
  } catch (error) {
    yield put({ type: GET_USER_LIST_FAILURE, payload: error });
  }
}

function* getSelectedUserSaga({ payload }) {
  try {
    const response = yield call(Get, `/User/getUserDetail?id=${payload.id}`, {}, {}, true);
    if (response && !response.error) {
      yield put({ type: GET_SELECTED_USER_SUCCESS, payload: response });
    } else {
      yield put({ type: GET_SELECTED_USER_FAILURE, payload: response });
    }
  } catch (error) {
    yield put({ type: GET_SELECTED_USER_FAILURE, payload: error });
  }
}

function* addUserSaga({ payload }) {
  try {
    const response = yield call(Post, `/User/registerUser`, payload, {}, false);
    if (response && !response.error) {
      yield put({ type: ADD_USER_SUCCESS, payload: response.data });
      alertMessage("Şirket basariyla eklendi", "success");
      yield put(getUserListRequest({}));
      yield put(push('/sirketler'));
    } else {
      yield put({ type: ADD_USER_FAILURE, payload: response.data });
      alertMessage(response.message, "error");
    }
  } catch (error) {
    alertMessage("User can not added successfuly", "error");
  }
}

function* editUserSaga({ payload }) {
  try {
    const response = yield call(Post, `/User/updateUser`, payload, {}, false);
    if (response && !response.error) {
      yield put({ type: EDIT_USER_SUCCESS, payload: response });
      alertMessage("Sirket basariyla guncellendi", "success");
      yield put(getUserListRequest({}));
      yield put(push('/sirketler'));
    } else {
      yield put({ type: EDIT_USER_FAILURE, payload: response });
      alertMessage(response.message, "error");
    }
  } catch (error) {
    alertMessage("Basarisiz", "error");
  }
}

function* deleteUserSaga({ payload }) {
  try {
    const response = yield call(Delete, `/User/deleteUser?userId=${payload.userId}`, {}, {}, false);
    if (response && !response.error) {
      yield put({ type: DELETE_USER_SUCCESS, payload: response });
      alertMessage("Şirket başarıyla silindi", "success");
      yield put(getUserListRequest({}));
    } else {
      yield put({ type: DELETE_USER_FAILURE, payload: response });
      alertMessage(response.message, "error");
    }
  } catch (error) {
    alertMessage("Basarisiz", "error");
    yield put({ type: DELETE_USER_FAILURE, payload: error });
  }
}

export default function* Saga() {
  yield takeLatest(GET_USER_LIST_REQUEST, getUserListSaga);
  yield takeLatest(ADD_USER_REQUEST, addUserSaga);
  yield takeLatest(EDIT_USER_REQUEST, editUserSaga);
  yield takeLatest(GET_SELECTED_USER_REQUEST, getSelectedUserSaga);
  yield takeLatest(DELETE_USER_REQUEST, deleteUserSaga);
}