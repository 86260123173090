import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'lodash/fp';
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { getProfileInfoRequest } from 'redux/profile/actions'
import { logOut } from 'redux/auth/actions'

import styles from './style.module.scss'


@connect(({ profile }) => ({ profile }))
class ProfileMenu extends React.Component {
  state = {
    count: 7,
    userData: {}
  }

  componentDidMount() {
    const { getProfileInfo } = this.props;
    getProfileInfo({})
  }

  logout = () => {
    const { logOut } = this.props
    logOut()
  }

  addCount = () => {
    let { count } = this.state
    count += 1
    this.setState({
      count,
    })
  }

  render() {
    const { history, profile } = this.props
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <a onClick={() => history.push('/apps/profile')} href="javascript: void(0);">
            <i className={`${styles.menuIcon} icmn-cog`} />
            <FormattedMessage id="topBar.profileMenu.accountSettings" />
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="javascript: void(0);" onClick={this.logout}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']}/* onVisibleChange={this.addCount} */>
        <div className={styles.dropdown}>
          <Avatar style={{ backgroundColor: "#f56a00" }} className={styles.avatar} size="large">
            {profile && profile.user && profile.user.first_name && profile.user.first_name.slice(0, 1) + "" + profile.user.last_name.slice(0, 1)}
          </Avatar>
        </div>
      </Dropdown>
    )
  }
}


const mapDispatchToProps = dispatch => ({
  getProfileInfo: payload => dispatch(getProfileInfoRequest(payload)),
  logOut: () => dispatch(logOut())
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withRouter,
)(ProfileMenu);