import antdData from 'antd/lib/locale-provider/de_DE'
import localeData from 'react-intl/locale-data/de'

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Nach sendungen suchen...',
  'topBar.buyNow': 'Buy Bundle $26',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
  'topBar.profileMenu.accountSettings': 'Kontoeinstellungen',

  'dashboard.activeShipments':"Aktive Sendungen",
  'dashboard.plannedShipments':"Geplante Sendungen",

  'shipment.shipmentsDate':"Termin",

  'sidebar.dashboard':"Mein Dashboard",

  'profile.myProfile':"Mein Profil",
  'profile.changePassword':"Passwort ändern"
}

export default {
  locale: 'de-DE',
  antdData,
  localeData,
  messages,
}
