export const GET_PROFILE_INFO_REQUEST = 'GET_PROFILE_INFO_REQUEST';
export const GET_PROFILE_INFO_SUCCESS = 'GET_PROFILE_INFO_SUCCESS';
export const GET_PROFILE_INFO_FAILURE = 'GET_PROFILE_INFO_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const getProfileInfoRequest = payload => ({
  type: GET_PROFILE_INFO_REQUEST,
  payload,
});

export const changePasswordRequest = payload => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload
})

export const updateProfileRequest = payload => ({
  type: UPDATE_PROFILE_REQUEST,
  payload
})
