import { Get } from "redux/services";
import { call, put, takeLatest } from "redux-saga/effects";
import { readLocalStorage } from 'helpers';
import { alertMessage } from "components/CleanUIComponents/AlertMessage";
import {
  GET_ROLES_FAILURE, GET_ROLES_REQUEST, GET_ROLES_SUCCESS
} from "./actions";

function* getRolesSaga() {
  try {
    const loginData = readLocalStorage("loginData");
    const response = yield call(Get, `/userRoles?type=admin`, {}, loginData.token, false);
    if (response && !response.error) {
      yield put({ type: GET_ROLES_SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_ROLES_FAILURE, payload: response });
      alertMessage('can not fetch roles', "error")
    }
  } catch (error) {
    yield put({ type: GET_ROLES_FAILURE, payload: error });
  }
}

export default function* Saga() {
  yield takeLatest(GET_ROLES_REQUEST, getRolesSaga);
}