import antdData from 'antd/lib/locale-provider/en_US'
import localeData from 'react-intl/locale-data/en'

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search for shipments...',
  'topBar.buyNow': 'Buy Bundle $26',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
  'topBar.profileMenu.accountSettings': 'Account Settings',

  'sidebar.customers':"Customers",
  'sidebar.logisticCompanies':"Logistic Companies",
  'sidebar.fixedRoutes':"Fixed Routes",

  'customer.companyName':"Company Name",
  'customer.contactPerson':"Contact Person",
  'customer.addCustomer':"Add Customer",

  'general.update':"Update",
  'general.general':"General",
  'general.type':"Type",
  'general.next':"Next",
  'general.previous':"Previous",
  'general.company':"Company",
  'general.addresses':"Addresses",
  'general.country':"Counrty",
  'general.name':"Name",
  'general.dashboard':"Dashboard",
  'general.logout':"Logout",
  'general.action':"Action",
  'general.inputSearchText':"input search text",
  
  'dashboard.activeShipments':"Active Shipments",
  'dashboard.plannedShipments':"Planned Shipments",

  'shipment.shipmentsDate':"Shipments Date",
  'shipment.addNewShipment':"Add New Shipment",
  'shipment.pickUpDetails':"Pick-Up Details",
  'shipment.pickUpLocation':"Pick-Up Location",
  'shipment.dropOffDetails':"Drop-Off Details",
  'shipment.dropOffLocation':"Drop-Off Location",
  'shipment.booking':"Booking",
  'shipment.loadingDate':"Loading Date",
  'shipment.unLoadingDate':"Unloading Date",
  'shipment.timeWindowForLoading':"Time Window for Loading",
  'shipment.timeWindowForUnLoading':"Time Window for Unloading",
  'shipment.truckType':"Truck Type",
  'shipment.totalWeight':"Total Weight",
  'shipment.extras':"Extras",
  'shipment.houseNumber':"House Number",
  'shipment.addressAddition':"Address Addition",
  'shipment.city':"City",
  'shipment.postCode':"Post Code",
  'shipment.contactPersonPickUp':"Contact person at pick-up location",
  'shipment.openingTimes':"Opening Times of the Warehouse",
  'shipment.myShipments':"My Shipments",
  'shipment.newShipment':"New Shipment",
  'shipment.fixedRoutes':"Fixed Routes",
  'shipment.billing':"Billing",
  'shipment.report':"Reports",
  'shipment.pickUpDate':"Pick-Up Date",
  'shipment.dropOffDate':"Drop-Off Date",
  'shipment.price':"Price",


  'profile.myProfile':"My Profile",
  'profile.changePassword':"Change Password",
  'profile.firstName':"First Name",
  'profile.lastName':"Last Name",
  'profile.email':"Email",
  'profile.phone':"Phone",
  'profile.mobilePhone':"Mobile Phone",
  'profile.currentPassword':"Current password",
  'profile.newPassword':"New password",
  'profile.repeatNewPassword':"Repeat new password"
}

export default {
  locale: 'en-US',
  antdData,
  localeData,
  messages,
}
