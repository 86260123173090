import { message } from 'antd';
import { Post, Get } from "redux/services";
import { push } from 'react-router-redux';
import { call, put, takeLatest } from "redux-saga/effects";
import { writeLocalStorage, removeLocalStorage } from 'helpers';
import { alertMessage } from 'components/CleanUIComponents/AlertMessage';
import {
  AUTH_SUCCESS, AUTH_FAILURE, AUTH_REQUEST, AUTH_LOGOUT_SUCCESS, AUTH_LOGOUT_FAILURE, AUTH_LOGOUT,
  FORGET_PASSWORD_SUCCESS, FORGET_PASSWORD_FAILURE, FORGET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE, RESET_PASSWORD_REQUEST
} from "./actions";

const error = (title) => {
  message.error(title);
};

function* login({ payload }) {
  try {
    const response = yield call(Post, "/User/authanticate", payload, {}, false);
    console.log(response)
    if (response && !response.error) {
      writeLocalStorage('access_token', response.token)
      writeLocalStorage('user', { userId: response.userId, isAdmin: response.isAdmin })
      yield put({ type: AUTH_SUCCESS, payload: response });
    } else {
      yield put({ type: AUTH_FAILURE, payload: response.data });
      alertMessage(response.message, "error");
    }
  } catch (error) {
    yield put({ type: AUTH_FAILURE, payload: error });
  }
}

function* logout() {
  try {
    yield put({ type: AUTH_LOGOUT_SUCCESS });
    removeLocalStorage("access_token");
    removeLocalStorage("user");
    localStorage.clear();
    sessionStorage.clear();
    yield put({ type: "RESET" });
    yield put(push('/user/login'));
    // Enable this line if necessary
    // window.location.reload(true);
  } catch (error) {
    yield put({ type: AUTH_LOGOUT_FAILURE });
    // yield put({ type: LOADER_END });
  }
}

function* forgetPasswordSaga(email) {
  try {
    const response = yield call(Post, "/auth/forgetPassword", email, false);
    if (response && !response.error) {
      yield put({ type: FORGET_PASSWORD_SUCCESS, payload: response });
      yield put(push('/user/reset'));
      alertMessage("Verification code send to  mail", "success");
    } else {
      yield put({ type: FORGET_PASSWORD_FAILURE, payload: response.data });
      alertMessage(response.message, "error");
    }
  } catch (error) {
    yield put({ type: FORGET_PASSWORD_FAILURE, payload: error });
  }
}

function* resetPasswordSaga({ payload }) {
  try {
    const response = yield call(Post, "/auth/resetPassword", payload, false);
    if (response && !response.error) {
      yield put({ type: RESET_PASSWORD_SUCCESS, payload: response });
      yield put(push('/user/login'));
      alertMessage("Your password changed successfuly", "success");
    } else {
      yield put({ type: RESET_PASSWORD_FAILURE, payload: response.data });
      alertMessage(response.message, "error");
    }
  } catch (error) {
    yield put({ type: RESET_PASSWORD_FAILURE, payload: error });
  }
}

export default function* Saga() {
  yield takeLatest(AUTH_REQUEST, login);
  yield takeLatest(AUTH_LOGOUT, logout);
  yield takeLatest(FORGET_PASSWORD_REQUEST, forgetPasswordSaga);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga);
}