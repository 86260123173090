import { Post, Get } from "redux/services";
import { call, put, takeLatest } from "redux-saga/effects";
import { readLocalStorage } from 'helpers';
import { TWO_DIRECTION_INFO_FAILURE, TWO_DIRECTION_INFO_SUCCESS, TWO_DIRECTION_INFO_REQUEST } from "./actions";


function* getDirectionsInfo({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const response = yield call(Get, `/places/direction?origin=${payload.origin}&destination=${payload.destination}&weightTypeId=${payload.weightTypeId}&loadingDate=${payload.loadingDate}`, {}, loginData.token, true);
    if (response && !response.error) {
      yield put({ type: TWO_DIRECTION_INFO_SUCCESS, payload: response.count });
    } else {
      yield put({ type: TWO_DIRECTION_INFO_FAILURE, payload: response });
    }
  } catch (error) {
    yield put({ type: TWO_DIRECTION_INFO_FAILURE, payload: error });
  }
}

export default function* Saga() {
  yield takeLatest(TWO_DIRECTION_INFO_REQUEST, getDirectionsInfo);
}