import { Get, Post } from "redux/services";
import { call, put, takeLatest } from "redux-saga/effects";
import { push } from 'react-router-redux';
import { alertMessage } from "components/CleanUIComponents/AlertMessage";
import { readLocalStorage } from 'helpers';
import {
  GET_CENTRAL_LIST_REQUEST, GET_CENTRAL_LIST_SUCCESS, GET_CENTRAL_LIST_FAILURE,
  ADD_CENTRAL_FAILURE, ADD_CENTRAL_SUCCESS, ADD_CENTRAL_REQUEST, getCentralListRequest
} from "./actions";

function* getCentralsSaga({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const response = yield call(Get, "/shipments", payload, loginData.token, true);
    if (response && !response.error) {
      yield put({ type: GET_CENTRAL_LIST_SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_CENTRAL_LIST_FAILURE, payload: response });
    }
  } catch (error) {
    yield put({ type: GET_CENTRAL_LIST_FAILURE, payload: error });
  }
}

function* createCentralSaga({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const response = yield call(Post, `/shipments`, payload, loginData.token, false);
    if (response && !response.error) {
      yield put({ type: ADD_CENTRAL_SUCCESS, payload: response.data });
      alertMessage("Santral eklendi", "success");
      yield put(getCentralListRequest({}));
      // yield put(push('/shipment/myShipments'));
    } else {
      yield put({ type: ADD_CENTRAL_FAILURE, payload: response.data });
      alertMessage("Santral eklenemedi", "error");
    }
  } catch (error) {
    alertMessage("Santral eklenemedi", "error");
  }
}


export default function* Saga() {
  yield takeLatest(GET_CENTRAL_LIST_REQUEST, getCentralsSaga);
  yield takeLatest(ADD_CENTRAL_REQUEST, createCentralSaga);
}