import { GET_PERIOD_FAILURE, GET_PERIOD_REQUEST, GET_PERIOD_SUCCESS, GET_PERIOD_BY_USER_FAILURE, GET_PERIOD_BY_USER_REQUEST, GET_PERIOD_BY_USER_SUCCESS } from './actions';

const initialState = {
    data: null,
    error: null,
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_PERIOD_REQUEST:
            return { ...state, error: '' };
        case GET_PERIOD_SUCCESS:
            return { ...state, data: payload };
        case GET_PERIOD_FAILURE:
            return { ...state, error: payload };
        case GET_PERIOD_BY_USER_REQUEST:
            return { ...state, error: '' };
        case GET_PERIOD_BY_USER_SUCCESS:
            return { ...state, data: payload };
        case GET_PERIOD_BY_USER_FAILURE:
            return { ...state, error: payload };
        default:
            return state;
    }
}
