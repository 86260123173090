import { GET_SELECTED_CUSTOMER_FAILURE,GET_SELECTED_CUSTOMER_SUCCESS,GET_SELECTED_CUSTOMER_REQUEST } from './actions';

const initialState = {
  data: null,
  error: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
  case GET_SELECTED_CUSTOMER_REQUEST:
    return { ...state, error: '' };
  case GET_SELECTED_CUSTOMER_SUCCESS:
    return { ...state, data: payload };
  case GET_SELECTED_CUSTOMER_FAILURE:
    return { ...state, error: payload };
  default:
    return state;
  }
}
