import { message } from 'antd';
import { Get, Post, Put } from "redux/services";
import { call, put, takeLatest } from "redux-saga/effects";
import { push } from 'react-router-redux';
import { writeLocalStorage, readLocalStorage } from 'helpers';
import { alertMessage } from "components/CleanUIComponents/AlertMessage";
import {
  GET_PROFILE_INFO_REQUEST, GET_PROFILE_INFO_SUCCESS,
  GET_PROFILE_INFO_FAILURE, CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE, UPDATE_PROFILE_FAILURE, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS,
  getProfileInfoRequest
} from "./actions";

const error = (title) => {
  message.error(title);
};

function* getProfileInfo({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const response = yield call(Get, "/users/myProfile", payload, loginData.token, false);
    if (response && !response.error) {
      yield put({ type: GET_PROFILE_INFO_SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_PROFILE_INFO_FAILURE, payload: response });
      error(response.error);
    }
  } catch (error) {
    yield put({ type: GET_PROFILE_INFO_FAILURE, payload: error });
  }
}

function* changePasswordSaga({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const response = yield call(Post, "/auth/refreshPassword", payload, loginData.token, true);
    if (response && !response.error) {
      yield put({ type: CHANGE_PASSWORD_SUCCESS, payload: response.count });
      alertMessage("updated successfuly", "success");
    } else {
      yield put({ type: CHANGE_PASSWORD_FAILURE, payload: response });
      alertMessage("error", "error");
    }
  } catch (error) {
    yield put({ type: CHANGE_PASSWORD_FAILURE, payload: error });
  }
}

function* editProfileSaga({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const response = yield call(Put, "/users/myProfile", payload, loginData.token, true);
    if (response && !response.error) {
      yield put({ type: UPDATE_PROFILE_SUCCESS, payload: response.data });
      yield put(getProfileInfoRequest({}));
      alertMessage("updated successfuly", "success");
      yield put(getProfileInfoRequest({}));
      yield put(push('/apps/profile'));
    } else {
      yield put({ type: UPDATE_PROFILE_FAILURE, payload: response.data });
      alertMessage("can not updated successfuly", "error");
    }
  } catch (error) {
    alertMessage("can not updated successfuly", "error");
  }
}

export default function* Saga() {
  yield takeLatest(GET_PROFILE_INFO_REQUEST, getProfileInfo);
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordSaga);
  yield takeLatest(UPDATE_PROFILE_REQUEST, editProfileSaga);
}