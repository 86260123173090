import {  Get } from "redux/services";
import { call, put, takeLatest } from "redux-saga/effects";
import { readLocalStorage } from 'helpers';
import { alertMessage } from "components/CleanUIComponents/AlertMessage";
import { GET_COUNTRY_LIST_REQUEST, GET_COUNTRY_LIST_SUCCESS, GET_COUNTRY_LIST_FAILURE } from "./actions";

function* getCountryListSaga({ payload }) {
    try {
      const loginData = readLocalStorage("loginData");
      const response = yield call(Get, "/countries", payload,loginData.token, true);
      if (response && !response.error) {
        yield put({ type: GET_COUNTRY_LIST_SUCCESS, payload: response.data });
      } else {
        alertMessage("can not fetch countries","error")
        yield put({ type: GET_COUNTRY_LIST_FAILURE, payload: response });
      }
    } catch (error) {
      yield put({ type: GET_COUNTRY_LIST_FAILURE, payload: error });
    }
  }

 export default function* Saga() {
    yield takeLatest(GET_COUNTRY_LIST_REQUEST, getCountryListSaga);
  }