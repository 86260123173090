export const GET_CENTRAL_LIST_REQUEST = 'GET_CENTRAL_LIST_REQUEST';
export const GET_CENTRAL_LIST_SUCCESS = 'GET_CENTRAL_LIST_SUCCESS';
export const GET_CENTRAL_LIST_FAILURE = 'GET_CENTRAL_LIST_FAILURE';

export const GET_SELECTED_CENTRAL_REQUEST = 'GET_SELECTED_CENTRAL_REQUEST';
export const GET_SELECTED_CENTRAL_SUCCESS = 'GET_SELECTED_CENTRAL_SUCCESS';
export const GET_SELECTED_CENTRAL_FAILURE = 'GET_SELECTED_CENTRAL_FAILURE';

export const ADD_CENTRAL_REQUEST = 'ADD_CENTRAL_REQUEST';
export const ADD_CENTRAL_SUCCESS = 'ADD_CENTRAL_SUCCESS';
export const ADD_CENTRAL_FAILURE = 'ADD_CENTRAL_FAILURE';

export const getCentralListRequest = payload => ({
  type: GET_CENTRAL_LIST_REQUEST,
  payload,
});

export const getSelectedCentralListRequest = payload => ({
  type: GET_SELECTED_CENTRAL_REQUEST,
  payload,
});

export const addCentralRequest = payload => ({
  type: ADD_CENTRAL_REQUEST,
  payload,
});
