import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import auth from "./auth/sagas";
import profile from "./profile/sagas";
import users from "./users/sagas";
import shipments from "./shipments/sagas";
import countries from "./countries/sagas";
import company from "./company/sagas";
import address from "./address/sagas";
import directions from "./direction/sagas";
import weightTypes from "./weightTypes/sagas";
import transactions from "./trackAndStatus/sagas";
import customers from "./customers/sagas";
import roles from "./roles/sagas";
import centrals from "./centrals/sagas";
import periods from "./periods/sagas"
import scenarios from "./scenarios/sagas"

export default function* rootSaga() {
  yield all([user(), menu(), directions(), settings(),
  address(), auth(), countries(), profile(), centrals(), users(), shipments(), company(), weightTypes(), transactions(),
  customers(), roles(), periods(), scenarios()])
}
