import { message } from 'antd'

export const alertMessage = (title, type) => {
  if (type === "error") {
    message.error(title)
  }
  if (type === "success") {
    message.success(title)
  }
}
