import { WEIGHT_TYPE_LIST_REQUEST, WEIGHT_TYPE_LIST_SUCCESS, WEIGHT_TYPE_LIST_FAILURE } from './actions';

const initialState = {
  data: null,
  error: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
  case WEIGHT_TYPE_LIST_REQUEST:
    return { ...state, error: '' };
  case WEIGHT_TYPE_LIST_SUCCESS:
    return { ...state, data: payload };
  case WEIGHT_TYPE_LIST_FAILURE:
    return { ...state, error: payload };
  default:
    return state;
  }
}
