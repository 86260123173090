import { Get, Post } from "redux/services";
import { call, put, takeLatest } from "redux-saga/effects";
import { push } from 'react-router-redux';
import { alertMessage } from "components/CleanUIComponents/AlertMessage";
import { readLocalStorage } from 'helpers';
import { GET_TRANSACTION_LIST_FAILURE, GET_TRANSACTION_LIST_REQUEST, GET_TRANSACTION_LIST_SUCCESS } from "./actions";

function* getTransactionListSaga({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const response = yield call(Get, `/shipments/${payload.shipment_uuid}/transactions`, {}, loginData.token, true);
    if (response && !response.error) {
      yield put({ type: GET_TRANSACTION_LIST_SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_TRANSACTION_LIST_FAILURE, payload: response });
      alertMessage("cannot fetch transactions","error")
    }
  } catch (error) {
    yield put({ type: GET_TRANSACTION_LIST_FAILURE, payload: error });
    alertMessage("cannot fetch transactions","error")
  }
}


export default function* Saga() {
  yield takeLatest(GET_TRANSACTION_LIST_REQUEST, getTransactionListSaga);
}