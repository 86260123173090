import { GET_SELECTED_USER_REQUEST,GET_SELECTED_USER_FAILURE,GET_SELECTED_USER_SUCCESS } from './actions';

const initialState = {
  data: null,
  error: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
  case GET_SELECTED_USER_REQUEST:
    return { ...state, error: '' };
  case GET_SELECTED_USER_SUCCESS:
    return { ...state, data: payload };
  case GET_SELECTED_USER_FAILURE:
    return { ...state, error: payload };
  default:
    return state;
  }
}
