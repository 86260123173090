import { message } from 'antd';
import { Get } from "redux/services";
import { call, put, takeLatest } from "redux-saga/effects";
import { readLocalStorage } from 'helpers';
import { WEIGHT_TYPE_LIST_REQUEST, WEIGHT_TYPE_LIST_SUCCESS, WEIGHT_TYPE_LIST_FAILURE } from "./actions";

function* getWeightListSaga({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const response = yield call(Get, "/weightTypes", payload, loginData.token, true);
    if (response && !response.error) {
      yield put({ type: WEIGHT_TYPE_LIST_SUCCESS, payload: response.data });
    } else {
      yield put({ type: WEIGHT_TYPE_LIST_FAILURE, payload: response });
    }
  } catch (error) {
    yield put({ type: WEIGHT_TYPE_LIST_FAILURE, payload: error });
  }
}

export default function* Saga() {
  yield takeLatest(WEIGHT_TYPE_LIST_REQUEST, getWeightListSaga);
}