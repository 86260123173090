export const GET_COMPANY_INFO_REQUEST = 'GET_COMPANY_INFO_REQUEST';
export const GET_COMPANY_INFO_SUCCESS = 'GET_COMPANY_INFO_SUCCESS';
export const GET_COMPANY_INFO_FAILURE = 'GET_COMPANY_INFO_FAILURE';

export const GET_COMPANY_LIST_REQUEST = 'GET_COMPANY_LIST_REQUEST';
export const GET_COMPANY_LIST_SUCCESS = 'GET_COMPANY_LIST_SUCCESS';
export const GET_COMPANY_LIST_FAILURE = 'GET_COMPANY_LIST_FAILURE';

export const CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILURE = 'CREATE_COMPANY_FAILURE';

export const UPDATE_COMPANY_INFO_REQUEST = 'UPDATE_COMPANY_INFO_REQUEST';
export const UPDATE_COMPANY_INFO_SUCCESS = 'UPDATE_COMPANY_INFO_SUCCESS';
export const UPDATE_COMPANY_INFO_FAILURE = 'UPDATE_COMPANY_INFO_FAILURE';


export const getCompanyInfoRequest = payload => ({
  type: GET_COMPANY_INFO_REQUEST,
  payload,
});

export const getCompanyListRequest = payload => ({
  type: GET_COMPANY_LIST_REQUEST,
  payload,
});

export const updateCompanyInfoRequest = payload => ({
  type: UPDATE_COMPANY_INFO_REQUEST,
  payload,
});

export const createCompanyRequest = payload => ({
  type: CREATE_COMPANY_REQUEST,
  payload,
});