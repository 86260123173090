export const CREATE_CENTRAL_REQUEST = 'CREATE_CENTRAL_REQUEST';
export const CREATE_CENTRAL_SUCCESS = 'CREATE_CENTRAL_SUCCESS';
export const CREATE_CENTRAL_FAILURE = 'CREATE_CENTRAL_FAILURE';

export const GET_ALL_CENTRALS_REQUEST = 'GET_ALL_CENTRALS_REQUEST';
export const GET_ALL_CENTRALS_SUCCESS = 'GET_ALL_CENTRALS_SUCCESS';
export const GET_ALL_CENTRALS_FAILURE = 'GET_ALL_CENTRALS_FAILURE';

export const GET_CENTRAL_REQUEST = 'GET_CENTRAL_REQUEST';
export const GET_CENTRAL_SUCCESS = 'GET_CENTRAL_SUCCESS';
export const GET_CENTRAL_FAILURE = 'GET_CENTRAL_FAILURE';


export const DELETE_CENTRAL_REQUEST = 'DELETE_CENTRAL_REQUEST';
export const DELETE_CENTRAL_SUCCESS = 'DELETE_CENTRAL_SUCCESS';
export const DELETE_CENTRAL_FAILURE = 'DELETE_CENTRAL_FAILURE';

export const EDIT_CENTRAL_REQUEST = 'EDIT_CENTRAL_REQUEST';
export const EDIT_CENTRAL_SUCCESS = 'EDIT_CENTRAL_SUCCESS';
export const EDIT_CENTRAL_FAILURE = 'EDIT_CENTRAL_FAILURE';



export const createCentralRequest = (payload, afterSuccess) => ({
    type: CREATE_CENTRAL_REQUEST,
    payload,
    afterSuccess
});

export const getAllCentralRequest = payload => ({
    type: GET_ALL_CENTRALS_REQUEST,
    payload,
});

export const deleteCentralRequest = payload => ({
    type: DELETE_CENTRAL_REQUEST,
    payload,
});

export const getCentralRequest = payload => ({
    type: GET_CENTRAL_REQUEST,
    payload,
});

export const editCentralRequest = (payload, afterSuccess) => ({
    type: EDIT_CENTRAL_REQUEST,
    payload,
    afterSuccess
});