import { Get, Put, Post } from "redux/services";
import { call, put, takeLatest } from "redux-saga/effects";
import { readLocalStorage } from 'helpers';
import { push } from 'react-router-redux';
import { alertMessage } from "components/CleanUIComponents/AlertMessage";
import {
  GET_COMPANY_INFO_REQUEST, GET_COMPANY_INFO_SUCCESS, GET_COMPANY_INFO_FAILURE
  , UPDATE_COMPANY_INFO_FAILURE, UPDATE_COMPANY_INFO_REQUEST, UPDATE_COMPANY_INFO_SUCCESS,
  GET_COMPANY_LIST_REQUEST, GET_COMPANY_LIST_SUCCESS, GET_COMPANY_LIST_FAILURE, CREATE_COMPANY_FAILURE, CREATE_COMPANY_SUCCESS, CREATE_COMPANY_REQUEST
} from "./actions";

function* getCompanyListSaga({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const userData = readLocalStorage("userData");
    const response = yield call(Get, `/logisticsCompanies`, payload, loginData.token, true);
    if (response && !response.error) {
      yield put({ type: GET_COMPANY_LIST_SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_COMPANY_LIST_FAILURE, payload: response });
    }
  } catch (error) {
    yield put({ type: GET_COMPANY_LIST_FAILURE, payload: error });
  }
}

function* getCompanyInfoSaga({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const response = yield call(Get, `/logisticsCompanies/${payload.uuid}`, {}, loginData.token, true);
    console.log("aaaa", response);
    if (response && !response.error) {
      yield put({ type: GET_COMPANY_INFO_SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_COMPANY_INFO_FAILURE, payload: response });
    }
  } catch (error) {
    yield put({ type: GET_COMPANY_INFO_FAILURE, payload: error });
  }
}

function* editCompanyInfoSaga({ payload }) {
  try {
    const uuid = payload.uuid;
    delete payload.uuid;
    const loginData = readLocalStorage("loginData");
    const response = yield call(Put, `/logisticsCompanies/${uuid}`, payload, loginData.token, false);
    if (response && !response.error) {
      yield put({ type: UPDATE_COMPANY_INFO_SUCCESS, payload: response.data });
      yield put(push('/logisticCompanies'));
      alertMessage("updated successfuly", "success");
    } else {
      yield put({ type: UPDATE_COMPANY_INFO_FAILURE, payload: response.data });
      alertMessage("can not updated successfuly", "error");
    }
  } catch (error) {
    alertMessage("can not updated successfuly", "error");
  }
}

function* createCompanySaga({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const response = yield call(Post, `/logisticsCompanies`, payload, loginData.token, true);
    if (response && !response.error) {
      yield put({ type: CREATE_COMPANY_SUCCESS, payload: response.data });
      yield put(push('/logisticCompanies'));
      alertMessage("created successfuly", "success");
    } else {
      yield put({ type: CREATE_COMPANY_FAILURE, payload: response.data });
      alertMessage(response.message, "error");
    }
  } catch (error) {
    alertMessage("can not created successfuly", "error");
  }
}

export default function* Saga() {
  yield takeLatest(GET_COMPANY_INFO_REQUEST, getCompanyInfoSaga);
  yield takeLatest(UPDATE_COMPANY_INFO_REQUEST, editCompanyInfoSaga);
  yield takeLatest(GET_COMPANY_LIST_REQUEST, getCompanyListSaga);
  yield takeLatest(CREATE_COMPANY_REQUEST, createCompanySaga);
}

