import { Get, Put, Post, Delete } from "redux/services";
import { call, put, takeLatest } from "redux-saga/effects";
import { readLocalStorage } from 'helpers';
import { push } from 'react-router-redux';
import { alertMessage } from "components/CleanUIComponents/AlertMessage";
import {
    CREATE_CENTRAL_FAILURE, CREATE_CENTRAL_REQUEST, CREATE_CENTRAL_SUCCESS, DELETE_CENTRAL_FAILURE, DELETE_CENTRAL_REQUEST, DELETE_CENTRAL_SUCCESS,
    EDIT_CENTRAL_REQUEST, EDIT_CENTRAL_SUCCESS, getAllCentralRequest, GET_ALL_CENTRALS_FAILURE, GET_ALL_CENTRALS_REQUEST, GET_ALL_CENTRALS_SUCCESS,
    GET_CENTRAL_FAILURE, GET_CENTRAL_REQUEST, GET_CENTRAL_SUCCESS, EDIT_CENTRAL_FAILURE
} from "./actions";


function* createCentralSaga({ payload, afterSuccess }) {
    try {
        const response = yield call(Post, `/Central/createCentral`, payload, {}, false);
        if (response && !response.error) {
            yield put({ type: CREATE_CENTRAL_SUCCESS, payload: response });
            afterSuccess();
            yield put(getAllCentralRequest({}));
            alertMessage("Santral başarıyla oluşturuldu", "success");
        } else {
            yield put({ type: CREATE_CENTRAL_FAILURE, payload: response });
            alertMessage(response.message, "error");
        }
    } catch (error) {
        alertMessage("Başarısız", "error");
        yield put({ type: CREATE_CENTRAL_FAILURE, payload: error });
    }
}

function* getAllCentralsSaga({ payload }) {
    try {
        const response = yield call(Get, `/Central/getAllCentals`, {}, {}, false);
        if (response && !response.error) {
            yield put({ type: GET_ALL_CENTRALS_SUCCESS, payload: response });
        } else {
            yield put({ type: GET_ALL_CENTRALS_FAILURE, payload: response });
            alertMessage(response.message, "error");
        }
    } catch (error) {
        yield put({ type: GET_ALL_CENTRALS_FAILURE, payload: error });
        alertMessage("Failed to fetch", "error");
    }
}

function* getCentralsSaga({ payload }) {
    try {
        const response = yield call(Get, `/Central/getCentralById?id=${payload.id}`, {}, {}, false);
        if (response && !response.error) {
            yield put({ type: GET_CENTRAL_SUCCESS, payload: response });
        } else {
            yield put({ type: GET_CENTRAL_FAILURE, payload: response });
            alertMessage(response.message, "error");
        }
    } catch (error) {
        yield put({ type: GET_CENTRAL_REQUEST, payload: error });
        alertMessage("Failed to fetch", "error");
    }
}

function* deleteCentralSaga({ payload }) {
    try {
        const response = yield call(Delete, `/Central/deleteCentral?centralId=${payload.centralId}`, {}, {}, false);
        if (response && !response.error) {
            yield put({ type: DELETE_CENTRAL_SUCCESS, payload: response });
            alertMessage("Santral başarıyla silindi", "success");
            yield put(getAllCentralRequest({}));
        } else {
            yield put({ type: DELETE_CENTRAL_FAILURE, payload: response });
            alertMessage(response.message, "error");
        }
    } catch (error) {
        alertMessage("Basarisiz", "error");
        yield put({ type: DELETE_CENTRAL_FAILURE, payload: error });
    }
}

function* editCentralSaga({ payload, afterSuccess }) {
    try {
        const response = yield call(Post, `/Central/updateCentral`, payload, {}, false);
        if (response && !response.error) {
            yield put({ type: EDIT_CENTRAL_SUCCESS, payload: response });
            alertMessage("Santral Başarıyla Güncellendi", "success");
            afterSuccess()
            yield put(getAllCentralRequest({}));

        } else {
            yield put({ type: EDIT_CENTRAL_FAILURE, payload: response });
            alertMessage(response.message, "error");
        }
    } catch (error) {
        alertMessage("Basarisiz", "error");
        yield put({ type: EDIT_CENTRAL_FAILURE, payload: error });
    }
}

export default function* Saga() {
    yield takeLatest(CREATE_CENTRAL_REQUEST, createCentralSaga);
    yield takeLatest(GET_ALL_CENTRALS_REQUEST, getAllCentralsSaga);
    yield takeLatest(DELETE_CENTRAL_REQUEST, deleteCentralSaga);
    yield takeLatest(GET_CENTRAL_REQUEST, getCentralsSaga);
    yield takeLatest(EDIT_CENTRAL_REQUEST, editCentralSaga);
}

