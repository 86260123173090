export const GET_CUSTOMER_LIST_REQUEST = 'GET_CUSTOMER_LIST_REQUEST';
export const GET_CUSTOMER_LIST_SUCCESS = 'GET_CUSTOMER_LIST_SUCCESS';
export const GET_CUSTOMER_LIST_FAILURE = 'GET_CUSTOMER_LIST_FAILURE';

export const GET_SELECTED_CUSTOMER_REQUEST = 'GET_SELECTED_CUSTOMER_REQUEST';
export const GET_SELECTED_CUSTOMER_SUCCESS = 'GET_SELECTED_CUSTOMER_SUCCESS';
export const GET_SELECTED_CUSTOMER_FAILURE = 'GET_SELECTED_CUSTOMER_FAILURE';

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';

export const EDIT_CUSTOMER_REQUEST = 'EDIT_CUSTOMER_REQUEST';
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE';

export const getCustomerListRequest = payload => ({
  type: GET_CUSTOMER_LIST_REQUEST,
  payload,
});

export const createCustomerRequest = payload => ({
  type: CREATE_CUSTOMER_REQUEST,
  payload
})

export const editCustomerRequest = payload => ({
  type: EDIT_CUSTOMER_REQUEST,
  payload
})

export const getSelectedCustomerRequest = payload => ({
  type: GET_SELECTED_CUSTOMER_REQUEST,
  payload
})