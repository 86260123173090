import { Get, Put, Post } from "redux/services";
import { call, put, takeLatest } from "redux-saga/effects";
import { readLocalStorage } from 'helpers';
import { push } from 'react-router-redux';
import { alertMessage } from "components/CleanUIComponents/AlertMessage";
import {
  GET_CUSTOMER_LIST_SUCCESS, getCustomerListRequest, GET_CUSTOMER_LIST_FAILURE, GET_CUSTOMER_LIST_REQUEST,
  CREATE_CUSTOMER_SUCCESS, CREATE_CUSTOMER_REQUEST, CREATE_CUSTOMER_FAILURE
  , GET_SELECTED_CUSTOMER_FAILURE, GET_SELECTED_CUSTOMER_REQUEST, GET_SELECTED_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILURE, EDIT_CUSTOMER_REQUEST, EDIT_CUSTOMER_SUCCESS
} from "./actions";

function* getCustomerListSaga({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const userData = readLocalStorage("userData");
    const response = yield call(Get, `/customers`, payload, loginData.token, true);
    if (response && !response.error) {
      yield put({ type: GET_CUSTOMER_LIST_SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_CUSTOMER_LIST_FAILURE, payload: response });
      alertMessage("can not fetch customers", "error");
    }
  } catch (error) {
    yield put({ type: GET_CUSTOMER_LIST_FAILURE, payload: error });
    alertMessage("can not fetch customers", "error");
  }
}

function* getSelectedCustomerSaga({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const userData = readLocalStorage("userData");
    const response = yield call(Get, `/customers/${payload.uuid}`, {}, loginData.token, true);
    if (response && !response.error) {
      yield put({ type: GET_SELECTED_CUSTOMER_SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_SELECTED_CUSTOMER_FAILURE, payload: response });
      alertMessage(response.message, "error");
    }
  } catch (error) {
    yield put({ type: GET_SELECTED_CUSTOMER_FAILURE, payload: error });
    alertMessage("can not fetch customers", "error");
  }
}

function* createCustomerSaga({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const response = yield call(Post, `/customers`, payload, loginData.token, false);
    if (response && !response.error) {
      yield put({ type: CREATE_CUSTOMER_SUCCESS, payload: response.data });
      alertMessage("Customer created successfuly", "success");
      yield put(getCustomerListRequest({}));
      yield put(push('/customers'));
    } else {
      yield put({ type: CREATE_CUSTOMER_FAILURE, payload: response.data });
      alertMessage(response.message, "error");
    }
  } catch (error) {
    alertMessage("Customer can not created successfuly", "error");
  }
}

function* editCustomerSaga({ payload }) {
  try {
    const loginData = readLocalStorage("loginData");
    const response = yield call(Put, `/customers/${payload.uuid}`, payload, loginData.token, false);
    if (response && !response.error) {
      yield put({ type: EDIT_CUSTOMER_SUCCESS, payload: response.data });
      alertMessage("Customer updated successfuly", "success");
      yield put(getCustomerListRequest({}));
      yield put(push('/customers'));
    } else {
      yield put({ type: EDIT_CUSTOMER_FAILURE, payload: response.data });
      alertMessage(response.message, "error");
    }
  } catch (error) {
    alertMessage("Customer can not updated successfuly", "error");
  }
}

export default function* Saga() {
  yield takeLatest(GET_CUSTOMER_LIST_REQUEST, getCustomerListSaga);
  yield takeLatest(CREATE_CUSTOMER_REQUEST, createCustomerSaga);
  yield takeLatest(GET_SELECTED_CUSTOMER_REQUEST, getSelectedCustomerSaga);
  yield takeLatest(EDIT_CUSTOMER_REQUEST, editCustomerSaga);
}

